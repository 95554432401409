<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="400"
    >
      <v-card class="kiosk-confirm-dialog">
        <v-card-title class="">
          <h3 class="success-heading" text-center>Success!</h3>
        </v-card-title>
        <v-card-text class="card-text text-center">Your booking has been confirmed. Check your email for details.</v-card-text>
        <v-card-actions style="padding-bottom: 12px;">
          <div class="row">
            <div class="col-4">
              <v-btn
                class="close-btn"
                color="darken-2"
                text
                @click="$emit('close')"
              >
                Close
              </v-btn>
            </div>
            <div class="col-4">
             <v-btn
                class="confirm-btn"
                color="darken-2"
                text
                @click="viewTickets('pos')"
              >
                View Ticket
              </v-btn>
            </div>
            <div class="col-4 hide-on-ipad hide-on-iphone">
             <v-btn
                class="confirm-btn"
                color="darken-2"
                text
                @click="downloadPdf('pos')"
              >
                Print Ticket
              </v-btn>
            </div>
          </div>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTicketPopup"
      persistent
      max-width="400"
    >
      <v-card class="kiosk-ticket-dialog" id="tp">
        <v-card-title class="">
          <h3 class="success-heading" text-center>Ticket{{tickets.length > 1?'s':''}}</h3>
        </v-card-title>
        <v-card-text class="card-text text-center fixed">
          <div subheader v-if="tickets.length > 0">
            <div class="row ticket-box" v-for="(item, index) in tickets" :key="index">
              <div class="col-sm-4">
                <div class="subhead pt-10">Guest {{index+1}}:</div>
              </div>
              <div class="col-sm-8">
                <div class="subhead pt-10">{{ item.customer.first_name }} {{ item.customer.last_name }}</div>
              </div>
              <div class="col-sm-12">
                  <div class="subhead font-14">{{ item.ticket_code }}<br></div> 
                  <div class="subhead qr-div"><qr-code :text="item.ticket_qr_code"></qr-code></div> 
              </div>
             
            </div>
          </div>
        </v-card-text>
      
        <v-card-actions>
          <div class="col-4">
              <v-btn
                class="close-btn"
                color="darken-2"
                text
                @click="showTicketPopup = false;"
              >
                Close
              </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  
</template>
<script >
export default {
    props: {
      show: { type: Boolean, default: false},
      orderId:{type:Number, default: null}  
    },
    data() {
      return {
        tickets: [],
        showTicketPopup:false,
      }
    },
    mounted() {
      this.screenWidth = window.innerWidth;
    },
  
    methods: {
      downloadPdf(type) {
        if(this.orderId){
          this.showLoader("Generating..");
          this.$http
            .get(`venues/orders/booking-tickets-pdf/${this.orderId}?type=${type}`, {
              responseType: "blob",
            })
            .then((response) => {
              if (response.status == 200) {
                this.printFile(response);
                this.hideLoader();
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
        }else{
          this.showError("Sorry contact to support");
        }
      },
      viewTickets(type) {
        if(this.orderId){
          this.showLoader("Generating..");
          this.$http
            .get(`venues/orders/booking-tickets/${this.orderId}?type=${type}`)
            .then((response) => {
              if (response.status == 200) {
                this.tickets = response.data.data;
                this.showTicketPopup=true;
                this.hideLoader();
              }
            })
            .catch((error) => {
               this.hideLoader();
              this.errorChecker(error);
            });
        }else{
          this.showError("Sorry contact to support");
        }
      },
      /*
      printHtml(){
        var prtContent = document.getElementById("tp");
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      }*/
    }
}
</script>
<style>
.kiosk-confirm-dialog .card-text,.kiosk-ticket-dialog .card-text{
    font-size: 18px;
}
.kiosk-confirm-dialog button.close-btn,.kiosk-ticket-dialog button.close-btn{
    text-align: center;
    display: inline-block;
    width: 100%;
    background-color: #f7f7f7;
    font-weight: 600;
    color: #000 !important;
}
.kiosk-confirm-dialog button.confirm-btn{
    text-align: center;
    display: inline-block;
    width: 100%;
    background-color: #86e2ff !important;
    font-weight: 600;
    color: #000 !important;
}
.kiosk-confirm-dialog h3.success-heading,.kiosk-ticket-dialog h3.success-heading {
    text-align: center;
    margin: 0 auto;
    padding: 12px;
    font-size: 28px;
    border-bottom:1px solid;
}
.kiosk-ticket-dialog .fixed {
    max-height: 420px;
    overflow-y: scroll;
}
.kiosk-ticket-dialog .ticket-box{
    border: 1px solid #ccc;
    margin: 5px 0px;
}
.kiosk-ticket-dialog .subhead{
  font-weight: 600;
}

.kiosk-ticket-dialog .qr-div{
    margin-left:30px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.kiosk-ticket-dialog .font-14{
  font-size:14px;
}
/* Hide button on iPad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 1) {
  .hide-on-ipad {
    display: none;
  }
}

/* Hide button on iPhone */
@media only screen and (max-device-width: 480px) {
  .hide-on-iphone {
    display: none;
  }
}
</style>