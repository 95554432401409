<template>
  <div class="customer-box">
    <v-tooltip bottom v-if="addOn == 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="red"
          fab
          x-small
          absolute
          top
          right
          dark
          style="top: -9px;right: 0px;"
          @click="$emit('removeAddOnCustomer')"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      Delete
    </v-tooltip>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <mobile-field
          solo
          :outlined="false"
          v-model="customer.mobile"
          label="Mobile Number*"
          @validated="searchCustomerData($event)"
        ></mobile-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="customer.name"
          solo
          background-color="#fff"
          label="Name*"
          :readonly="customer.customer_id > 0"
          required
          :rules="[(v) => !!v || 'Name is required']"
          @blur="$emit('setCustomerData',customer)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="customer.email"
          label="Email*"
          solo
          background-color="#fff"
          :readonly="customer.customer_id > 0"
          required
          :rules="[
            (v) => !!v || 'Email is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ]"
          @blur="$emit('setCustomerData',customer)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="field.gender.is_visible">
        <v-select
          :items="['Male', 'Female']"
          :placeholder="`Gender${field.gender.is_required ? '*' : ''}`"
          :label="`Gender${field.gender.is_required ? '*' : ''}`"
          solo
          :menu-props="{ bottom: true, offsetY: true }"
          v-model="customer.gender"
          background-color="#fff"
          :rules="genderRule"
          @change="$emit('setCustomerData',customer)"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="field.dob.is_visible">
        <date-of-birth
          :placeholder="`Date of Birth${field.dob.is_required ? '*' : ''}`"
          :label="`Date of Birth${field.dob.is_required ? '*' : ''}`"
          v-model="customer.dob"
          :dense="false"
          :outlined="false"
          :rules="dobRule"
          solo
          @input="$emit('setCustomerData',customer)"
        >
        </date-of-birth>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="field.nationality.is_visible">
        <v-autocomplete
          :items="countries"
          :hint="`Nationality${field.nationality.is_required ? '*' : ''}`"
          :label="`Nationality${field.nationality.is_required ? '*' : ''}`"
          item-value="id"
          item-text="name"
          solo
          v-model="customer.country_id"
          background-color="#fff"
          :rules="nationalityRule"
          @change="$emit('setCustomerData',customer)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="field.tag.is_visible">
        <v-select
          :hint="`Tag${field.tag.is_required ? '*' : ''}`"
          :label="`Tag${field.tag.is_required ? '*' : ''}`"
           solo
          :items="tags"
          item-value="id"
          item-text="name"
          :menu-props="{ bottom: true, offsetY: true }"
          v-model="customer.tag_id"
          :rules="tagRule"
          background-color="#fff"
          @change="$emit('setCustomerData',customer)"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    addOn: { type: Number, default: null },
    perCapacity: { type: Number, default: 0 },
    singleBookingForm: { type: Object },
    countries: { type: Array },
    tags:{type: Array},
    customer: { type: Object },
    field:{type:Object},
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    genderRule() {
      const rules = [];
      if (this.field.gender.is_required) {
        const rule = (v) => !!v || "Gender is required";
        rules.push(rule);
      }
      return rules;
    },
    nationalityRule() {
      const rules = [];
      if (this.field.nationality.is_required) {
        const rule = (v) => !!v || "Nationality is required";
        rules.push(rule);
      }
      return rules;
    },
    dobRule() {
      const rules = [];
      if (this.field.dob.is_required) {
        const rule = (v) => !!v || "Date of Birth is required";
        rules.push(rule);
      }
      return rules;
    },
    tagRule() {
      const rules = [];
      if (this.field.tag.is_required) {
        const rule = (v) => !!v || "Tag is required";
        rules.push(rule);
      }
      return rules;
    },
  },
  methods: {
    sendCustomerData(data) {
      this.$emit("setCustomerData", data);
    },
    searchCustomerData(mobile) {
      if (this.loading) return;
      this.showLoader("Checking");
      this.loading = true;
      this.$http
        .get(`venues/customers?mobile=${mobile}`)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.loading = false;
            let data = response.data.data;
            if (data.length) {
              this.$set(this.customer, "email", data[0].email);
              this.$set(this.customer, "customer_id", data[0].id);
              this.$set(this.customer, "first_name", data[0].first_name);
              this.$set(this.customer, "last_name", data[0].last_name);
              this.$set(this.customer, "name", data[0].name);
              this.$set(this.customer, "customer_type", "normal");
              this.$set(this.customer, "gender", data[0].gender);
              this.$set(this.customer, "country_id", data[0].country_id);
              this.$set(this.customer, "dob", data[0].dob);
              this.$set(this.customer, "tag_id", data[0].tag_id);
              this.sendCustomerData(this.customer);
            } else {
              this.$set(this.customer, "email", "");
              this.$set(this.customer, "customer_id", "");
              this.$set(this.customer, "first_name", "");
              this.$set(this.customer, "last_name", "");
              this.$set(this.customer, "name", "");
              this.$set(this.customer, "customer_type", "");
              this.$set(this.customer, "gender", null);
              this.$set(this.customer, "country_id", null);
              this.$set(this.customer, "dob", null);
              this.$set(this.customer, "tag_id", null);
              this.sendCustomerData(this.customer);
            }
          }
        })
        .catch(() => {
          this.hideLoader();
          this.slotMessage = "Slot not available";
          // this.errorChecker(error);
        });
    },
  },
};
</script>
<style>
.customer-box{
  position: relative;
}
</style>